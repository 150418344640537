<template>
  <div style="height: 100%; width: 100%; overflow-y: auto">
    <div class="d-flex justify-center my-2">
      <div 
        :style="{
          'width': $vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? '300px' : '30vw',
          'max-width': '100vw'
        }"
      >
        <div class="text-center my-2">
          <span class="font-weight-light">Totale</span>
          <br>
          <span class="text-overline" style="font-size: 1.5em !important">{{totalFormatted}}</span>
        </div>
        <template v-if="!hidePaidAmounts">
          <div class="my-4 d-flex justify-center align-center">
            <NumericKeyboardInput
              v-model="localPaidWithCash"
              label="In contanti"
              prepend-icon="mdi-cash-multiple"
              live
              :disabled="Number(localNotPaid) > 0"
              @input="emitUpdate"
              :suffixs="['€', ',00€']"
              :min="0"
              :max="total"
            ></NumericKeyboardInput>
            <v-btn 
              icon
              class="mr-10 ml-2"
              @click="setAllPaidWithCash"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <div class="my-4 d-flex justify-center align-center">
            <NumericKeyboardInput
              v-model="localPaidWithCard"
              label="Con carta"
              prepend-icon="mdi-credit-card-outline"
              live
              :disabled="Number(localNotPaid) > 0"
              @input="emitUpdate"
              :suffixs="['€', ',00€']"
              :min="0"
              :max="total"
            ></NumericKeyboardInput>
            <v-btn 
              icon
              class="mr-10 ml-2"
              @click="setAllPaidWithCard"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <div class="my-4 d-flex justify-center align-center">
            <NumericKeyboardInput
              v-model="localPaidWithTickets"
              label="Con ticket o altro"
              prepend-icon="mdi-ticket-confirmation"
              live
              :disabled="Number(localNotPaid) > 0"
              @input="emitUpdate"
              :suffixs="['€', ',00€']"
              :min="0"
              :max="total"
            ></NumericKeyboardInput>
            <v-btn 
              icon
              class="mr-10 ml-2"
              @click="setAllPaidWithTicket"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <div class="my-4 d-flex justify-center align-center">
            <NumericKeyboardInput
              v-model="localPaidWithCheck"
              label="Con assegno"
              prepend-icon="mdi-checkbook"
              live
              @input="emitUpdate"
              :disabled="Number(localNotPaid) > 0"
              :suffixs="['€', ',00€']"
              :min="0"
              :max="total"
            ></NumericKeyboardInput>
            <v-btn 
              icon
              class="mr-10 ml-2"
              @click="setAllPaidWithCheck"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <div class="my-4 d-flex justify-center align-center" v-if="!hideNotPaid">
            <NumericKeyboardInput
              v-model="localNotPaid"
              label="Non Pagato"
              prepend-icon="mdi-alert"
              live
              @input="emitUpdate"
              :suffixs="['€', ',00€', '0€']"
              :min="0"
              :max="total"
            ></NumericKeyboardInput>
            <v-btn 
              icon
              class="mr-10 ml-2"
              @click="setAllNotPaid"
            >
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          <div 
            class="text-center mt-2 font-weight-light" 
            :style="{
              'color': localToBePaid != 0 ? 'red' : 'black'
            }"
          >Ancora da pagare</div>
          <div 
            class="text-overline text-center"
            style="font-size: 1.5em !important"
            :style="{
              'color': localToBePaid != 0 ? 'red' : 'black'
            }"
          >{{Number(localToBePaid).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</div>
          <div 
            class="text-center mt-2 font-weight-light" 
          >Resto</div>
          <div 
            class="text-overline text-center"
            style="font-size: 1.5em !important"
          >{{cashChange}}</div>
          <div class="align-center justify-center text-center">
            <v-btn
              class="mt-2"
              color="white"
              style="width: 55%"
              @click="billSummary" 
            >
              <span style="font-size: 0.8vw"> Pre conto </span>
              <v-icon right>
                mdi-receipt
              </v-icon>
            </v-btn>
          </div>
        </template>
      </div>
      <div
        :style="{
          'width': $vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? '300px' : '30vw',
          'max-width': '100vw',
          'max-height': '40vh', 
          'overflow-y': 'auto',
          'position': 'relative'
        }"
        ref="scrollingReceipt" 
        @scroll="handleReceiptScroll"
      >
        <div 
          v-for="item in providedEntities"
          :key="getItemKey(item)"
          class="my-2"
          @dblclick="$emit('dblclick-row', item, $event)"
        >
          <div class="d-flex align-center">
            <span class="font-weight-bold">
              <v-badge
                :content="item.ghostQuantity"
                inline
                :value="!!item.ghost"
                color="error"
              >
                {{getItemName(item)}}
              </v-badge>
            </span>
            <v-spacer></v-spacer>
            <span right class="text-overline" style="line-height: 1em !important">{{calculateTotalItemPrice(item).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span>
          </div>
          <div class="text-caption">
            x {{getItemQuantity(item)}}
          </div>
           <div v-if="!!getItemDiscount(item) && !discount && !!item.ghost" class="d-flex mb-2">
            <v-spacer></v-spacer>
            <span right class="text-overline" style="line-height: 1em !important">- {{Number(getItemDiscount(item)).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="my-2" v-if="discount">
          <div class="d-flex align-center">
            <span class="font-weight-bold">Sconto</span>
            <v-spacer></v-spacer>
            <span right class="text-overline" style="line-height: 1em !important">- {{Number(discount).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span>
          </div>
          <div class="text-caption">
            x 1
          </div>
          <v-divider></v-divider>
        </div>
        <div class="my-2" v-if="cardSubtract">
          <div class="d-flex align-center">
            <span class="font-weight-bold">Credito Tessera Utilizzato</span>
            <v-spacer></v-spacer>
            <span right class="text-overline" style="line-height: 1em !important">- {{Number(cardSubtract).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}}</span>
          </div>
          <div class="text-caption">
            x 1
          </div>
          <v-divider></v-divider>
        </div>
        <v-fab-transition>
          <v-btn
            color="primary"
            fab
            small
            dark
            style="position: sticky; left: 80%; bottom: 15px"
            v-if="receiptScrollY == 0 && canScrollReceipt"
            @click="scrollReceipt"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-fab-transition>
      </div>
      <div 
        :style="{
          'width': $vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? '300px' : '30vw',
          'max-width': '100vw',
        }"
        class="d-flex flex-column align-center"
      >
        <div class="font-weight-light">Cassa Aperta da:</div>
        <OperatorAvatar
          class="mt-8"
          :size="100"
          :value="openedCashDesk.operator"
        ></OperatorAvatar>
        <div class="text-center text-h6 mt-2">
          {{openedCashDesk.operator.firstname}}
        </div>
        <div v-if="numberOf" class="mt-16 font-weight-bold">
          Promozione con pagamento a rate
          <div>
            Numero di rate da effettuare: {{numberOf}}
          </div>
          <div>
            Promozione da pagare entro il  {{expireDate.getDate()}}/{{Number(expireDate.getMonth()) + 1}}/{{expireDate.getFullYear()}}
          </div>
        </div>
        <div class="mt-16">
          <v-text-field
            v-model="localLotteryCode"
            label="Codice Lotteria"
            append-outer-icon="mdi-floppy"
            hide-details="auto"
            filled
            dense
            :loading="loadingCustomerLotteryCode"
            @click:append-outer="saveLotteryCode"
          >
            <div slot="prepend">
              <v-checkbox
                v-model="localUseLotteryCode"
                :disabled="!canUseLotteryCode"
                class="mb-4"
                hide-details="auto"
                dense
                @change="$emit('update:use-lottery-code', $event)"
              ></v-checkbox>
            </div>
          </v-text-field>
        </div>
      </div>
    </div>
    <div class="d-flex justify-center mt-4">
      <v-btn 
        text
        @click="$emit('go-back')"
        color="error"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Indietro
      </v-btn>
      <v-btn 
        text
        @click="emitConfirm()"
        color="success"
        :disabled="confirmDisabled"
      >
        <v-icon left>mdi-check</v-icon>
        Conferma
      </v-btn>
    </div>
  </div>
</template>

<script>
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue';
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import Printer from '@/services/devices/rtPrinter';
import customerService from '@/services/customers/customers.service.js'
import billServiceCalculator from '@/services/bills/billService.calculator.js';
import billBarCalculator from '@/services/bills/billBar.calculator.js';

export default {
  name: "PaymentSummary",
  components: {
    NumericKeyboardInput,
    OperatorAvatar
  },
  data: function() {
    return {
      localPaidWithCash: this.paidWithCash,
      localPaidWithCard: this.paidWithCard,
      localPaidWithTickets: this.paidWithTickets,
      localPaidWithCheck: this.paidWithCheck,
      localNotPaid: this.notPaid,
      receiptScrollY: 0,
      canScrollReceipt: false,
      loadingCustomerLotteryCode: false,
      localUseLotteryCode: !!this.customer && !!this.customer.dataInvoice && !!this.customer.dataInvoice.lotteryCode,
      localLotteryCode: !!this.customer && !!this.customer.dataInvoice ? this.customer.dataInvoice.lotteryCode : undefined 
    }
  },
  mounted: function() {
    if(!this.canUseLotteryCode) this.localUseLotteryCode = false

    this.$emit('update:totalPaid', this.totalPaid)
    this.$emit('update:useLotteryCode', this.localUseLotteryCode)
    this.$emit('update:lotteryCode', this.localLotteryCode)

  },
  props: {
    total: {
      type: Number
    },
    providedEntities: {
      type: Array
    },
    itemKey: { 
      default: 'id' 
    },
    itemName: { 
      default: 'name' 
    },
    itemPrice: { 
      default: 'price' 
    },
    itemQuantity: { 
      default: 'quantity' 
    },
    itemDiscount: { 
      default: 'discount' 
    },
    openedCashDesk: {
      type: Object
    },
    paidWithCash: {
      type: Number,
      default: undefined
    },
    discount: {
      type: Number,
      default: undefined
    },
    cardSubtract: {
      type: Number,
      default: undefined
    },
    paidWithCard: {
      type: Number,
      default: undefined
    },
    paidWithTickets: {
      type: Number,
      default: undefined
    },
    paidWithCheck: {
      type: Number,
      default: undefined
    },
    notPaid: {
      type: Number,
      default: undefined
    },
    useLotteryCode: {
      type: Boolean,
      default: false
    },
    lotteryCode: {
      type: String,
      default: undefined
    },
    hidePaidAmounts: {
      type: Boolean,
      default: false
    },
    hideNotPaid: {
      type: Boolean,
      default: false
    },
    totalPaid: {
      type: Number,
      default: undefined
    },
    numberOf: {
      type: Number,
      default: undefined
    },
    expireDate: {
      type: Date,
      default: undefined
    },
    localBill: {
      type: Object,
      default: () => {}
    },
    localCustomerCard: {
      type: Object,
      default: () => {}
    },
    invoiceOrReceipt: {
      type: String,
    },
    customer: {
      type: Object
    },
    invoiceData: {
      type: Object
    },
    barOrSaloon: {
      type: 'bar' | 'saloon',
      default: 'saloon'
    },
    toBePaid: {
      type: Number,
      default: 0
    },
  },
  methods: {
    emitConfirm() {
      console.log('isNotPaidWithGhost', this.isNotPaidWithGhost)
      if (this.isNotPaidWithGhost) {
        this.$delegates.snackbar("Non è possibile confermare l'operazione con un servizio non pagato")
        return
      }
      this.$emit('confirm')
    },
    getItemName(item){
      if(typeof this.itemName == "function")
        return this.itemName(item)
      else return item[this.itemName]
    },
    getItemKey(item){
      if(typeof this.itemKey == "function")
        return this.itemKey(item)
      else return item[this.itemKey]
    },
    getItemPrice(item){
      if(typeof this.itemPrice == "function")
        return this.itemPrice(item)
      else return item[this.itemPrice]
    },
    getItemQuantity(item){
      if(typeof this.itemQuantity == "function")
        return this.itemQuantity(item)
      else return item[this.itemQuantity]
    },
    getItemDiscount(item) {
      if(typeof this.itemDiscount == "function")
        return this.itemDiscount(item)
      else return item[this.itemDiscount]
    },
    calculateTotalItemPrice(item){
      let price = Number(this.getItemPrice(item))
      let quantity = Number(this.getItemQuantity(item))
      return (price * quantity) 
    },
    setAllPaidWithCash() {
      this.localPaidWithCash = this.total
      this.localPaidWithCard = undefined
      this.localPaidWithTickets = undefined
      this.localPaidWithCheck = undefined
      this.localNotPaid = undefined
      this.emitUpdate()
    },
    setAllPaidWithCard() {
      this.localPaidWithCash = undefined
      this.localPaidWithCard = this.total
      this.localPaidWithTickets = undefined
      this.localPaidWithCheck = undefined
      this.localNotPaid = undefined
      this.emitUpdate()
    },
    setAllPaidWithTicket() {
      this.localPaidWithCash = undefined
      this.localPaidWithCard = undefined
      this.localPaidWithTickets = this.total
      this.localPaidWithCheck = undefined
      this.localNotPaid = undefined
      this.emitUpdate()
    },
    setAllPaidWithCheck() {
      this.localPaidWithCash = undefined
      this.localPaidWithCard = undefined
      this.localPaidWithTickets = undefined
      this.localPaidWithCheck = this.total
      this.localNotPaid = undefined
      this.emitUpdate()
    },
    setAllNotPaid() {
      this.localPaidWithCash = undefined
      this.localPaidWithCard = undefined
      this.localPaidWithTickets = undefined
      this.localPaidWithCheck = undefined
      this.localNotPaid = this.total
      this.emitUpdate()
    },
    emitUpdate() {
      this.$emit('update:paidWithCash', this.localPaidWithCash)
      this.$emit('update:paidWithCard', this.localPaidWithCard)
      this.$emit('update:paidWithTickets', this.localPaidWithTickets)
      this.$emit('update:paidWithCheck', this.localPaidWithCheck)
      this.$emit('update:notPaid', this.localNotPaid)
      this.$emit('update:toBePaid', this.localToBePaid)
    },
    handleReceiptScroll(event) {
      this.receiptScrollY = event.target.scrollTop
    },
    scrollReceipt() {
      if(!!this.$refs.scrollingReceipt) {
        this.$refs.scrollingReceipt.scroll({ top: 100, behavior: 'smooth' });
      }
    },
    calculateScrollReceipt() {
      if(!!this.$refs.scrollingReceipt) {
        this.canScrollReceipt = this.$refs.scrollingReceipt.scrollHeight > this.$refs.scrollingReceipt.offsetHeight
      }
    },
    saveLotteryCode() {
      this.loadingCustomerLotteryCode = true

      customerService.update({
        id: this.customer.id,
        dataInvoice: {
          lotteryCode: this.localLotteryCode
        }
      }).then((results) => {
        this.loadingCustomerLotteryCode = false
        this.$delegates.snackbar('Salvataggio avvenuto correttamente')
      })
    },
    billSummary() {
      let calculator
      if(this.barOrSaloon == 'bar') {
        calculator = billBarCalculator
      } else {
        calculator = billServiceCalculator
      }

      if(!!this.localBill) {
        if (this.providedEntities.length == 0) {
          this.$delegates.snackbar("Non sono presenti servizi o prodotti");
        } else {
          const totalServicesAndItems = this.providedEntities.map(billEntity => {
            let object = {
              quantity: billEntity.quantity,
              discount: billEntity.discount ? billEntity.discount : 0,
              discountDescription: billEntity.discountDescription ? billEntity.discountDescription : "",
              tax: "22,00 %"
            }

            if(!!billEntity.service) {
              object.name = billEntity.service.name
              object.additionalDescription = billEntity.billServiceOperators[0] ? billEntity.billServiceOperators[0].operator.firstname : "",
              object.price = billEntity.service.price

              if (!!billEntity.editPriceType && !!billEntity.editPriceValue) {
                object.price = calculator.calculateEditedPrice(billEntity)
                if (!!object.discount)
                  object.discount = calculator.calculateDiscountShot(billEntity, object.discount)
              }

            } else if (!!billEntity.item) {
              object.name = billEntity.item.name
              object.price = billEntity.item.priceSelling

              if (!!billEntity.editPriceType && !!billEntity.editPriceValue) {
                object.price = calculator.calculateEditedItemPrice(billEntity)
                if (!!object.discount)
                  object.discount = calculator.calculateDiscountShotItem(billEntity, object.discount)
              }
            }

            return object
          }).filter(el => !!el.quantity)


          if(this.invoiceOrReceipt == 'invoice') {
            Printer.printSummary({
              amount: calculator.toBePaid,
              invoiceData: {
                ...this.invoiceData,
                lastname: !!this.customer ? this.customer.lastname : ''
              },
              items: totalServicesAndItems
            }, true, "billSummary invoice")
          } else {
            let localTotalGhost = billServiceCalculator.totalGhost != undefined && billServiceCalculator.totalGhost > 0 ? 
              billServiceCalculator.discount != undefined ? Number(billServiceCalculator.totalGhost) - Number(billServiceCalculator.discount) : billServiceCalculator.ghost
              : undefined

            Printer.printSummary({
              amount: !!localTotalGhost ? localTotalGhost : calculator.toBePaid,
              items: totalServicesAndItems
            }, false, "billSummary receipt")
          }
        }
      } else if(!!this.localCustomerCard) {
        Printer.printSummary({
          amount: this.total,
          items: [{
            name: (this.providedEntities[0].service.name).replace("&", "e"),
            additionalDescription: "",
            price: Number(this.providedEntities[0].service.price),
            quantity: 1,
            tax: "22,00 %",
          }]
        },false, "billSummary customerCard")
      } else {
        let items = []
        for(let entities of this.providedEntities){
          let discount = 0
          if(entities.editPriceType == 'percentage')
            discount = entities.editPriceValue ? (Number(entities.service.price) / 100  * Number(entities.editPriceValue)) : 0
          else if(entities.editPriceType == 'number')
            discount = entities.editPriceValue ? Number(entities.editPriceValue): 0

          items.push({
            name: entities.service.name,
            additionalDescription: "",
            price: Number(entities.service.price) - discount,
            quantity: !!Number(entities.quantity) ? Number(entities.quantity) : 1,
            tax: Number(entities.tax) == 10 ? "10,00%" : "22,00 %",
          })
        }
        let discount = null
        if(this.discount)
          discount = {
            amount: Number(this.discount),
          }

        Printer.printSummary({
          amount: this.total,
          items: items,
          discount: discount
        },false, "billSummary")
      }
    },
  },
  watch: {
    localTotalPaid(newVal) {
      this.$emit('update:totalPaid', Number(Number(newVal).toFixed(2)))
    },
    localNotPaid(newVal) {
      if(!!newVal && newVal > 0){
        this.$nextTick(() => {
          this.setAllNotPaid()
        })
      }
    },
    customer(newVal) {
      if(!!newVal && !!newVal.dataInvoice) {
        this.localLotteryCode = newVal.dataInvoice.lotteryCode
        this.localUseLotteryCode = this.canUseLotteryCode && !!newVal.dataInvoice.lotteryCode
        this.$emit('update:useLotteryCode', this.localUseLotteryCode)
      }
    },
    localLotteryCode() {
      this.$emit('update:lotteryCode', this.localLotteryCode)
    },
    paidWithCash(newVal) {
      this.localPaidWithCash = newVal
    },
    paidWithCard(newVal) {
      this.localPaidWithCard = newVal
    },
    paidWithCheck(newVal) {
      this.localPaidWithCheck = newVal
    },
    paidWithTickets(newVal) {
      this.localPaidWithTickets = newVal
    },
    notPaid(newVal) {
      this.localNotPaid = newVal
    },
    canUseLotteryCode(newVal) {
      if(!newVal) {
        this.localUseLotteryCode = false
      }
    }
  },
  computed: {
    totalFormatted() {
      return this.total != undefined && this.total != null ? Number(this.total).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : 'Non Specificato'
    },
    localTotalPaid() {
      let result = 0
      if(!!this.localPaidWithCash) {
        if(!!this.invoiceData)
          this.invoiceData.paymentTermId = 1
        result = Number((result + this.localPaidWithCash).toFixed(2))
      }

      if(!!this.localPaidWithCard) {
        if(!!this.invoiceData)
          this.invoiceData.paymentTermId = 5
        result = Number((result + this.localPaidWithCard).toFixed(2))
      }

      if(!!this.localPaidWithTickets) {
        if(!!this.invoiceData)
          this.invoiceData.paymentTermId = 17
        result = Number((result + this.localPaidWithTickets).toFixed(2))
      }

      if(!!this.localPaidWithCheck) {
        if(!!this.invoiceData)
          this.invoiceData.paymentTermId = 2
        result = Number((result + this.localPaidWithCheck).toFixed(2))
      }

      if(!!this.localNotPaid) {
        result = Number((result + this.localNotPaid).toFixed(2))
      }
      return result
    },
    differenceTotalFromPaid() {
      let result = 0
      if(!!this.total) result = this.total

      return Number((result - this.localTotalPaid).toFixed(2))
    },
    localToBePaid() {
      return this.differenceTotalFromPaid < 0 ? 0 : this.differenceTotalFromPaid
    },
    cashChange() {
      return this.differenceTotalFromPaid < 0 ? (this.differenceTotalFromPaid * -1).toFixed(2) : 0
    },
    confirmDisabled() {
      return !this.hidePaidAmounts && this.localToBePaid != 0
    },
    canUseLotteryCode() {
      return this.paidWithCard > 0
    },
    isNotPaidWithGhost() {
      return this.localNotPaid !== undefined && this.localNotPaid !== null
        && (this.localNotPaid > 0 || 
          (
            this.localNotPaid == 0 && (this.localPaidWithCard == null || this.localPaidWithCard == undefined) &&
            (this.localPaidWithCash == null || this.localPaidWithCash == undefined) &&
            (this.localPaidWithTickets == null || this.localPaidWithTickets == undefined) &&
            (this.localPaidWithCheck == null || this.localPaidWithCheck == undefined)
          )
        ) && !!this.providedEntities
        && this.providedEntities.filter(el => !!el.ghost).length > 0
    }
  }
}
</script>

<style>

</style>